<template>
 <div>
    <v-row no-gutters class="pa-1">
        <v-col cols="12">
          <v-card v-if="!isOnline" flat class="text-center pa-4">
            
            <p style="margin-top: 120px;">
              <v-btn  x-large fab><v-icon>mdi-wifi-off</v-icon></v-btn>
            </p>
              Please connect to the Internet 
          </v-card>
          <v-card v-else class="pa-2" flat>
              <div class="text-center">
                  <span class="text-h4">{{$money(balance)}}</span><br/>
                  Current Balance
            </div>   
              <v-row no-gutter class="ma-1">
                      <v-divider></v-divider>
                  </v-row>
              <v-row>
                  <v-bottom-navigation class="elevation-0 mb-2">
                      <v-btn @click="sendform=true">
                        <span>Transfer</span>

                        <v-icon large color="info">mdi-database-arrow-right</v-icon>
                      </v-btn>
                      <v-btn @click="$router.push('/buy-credits')">
                        <span>Buy Credits </span>

                        <v-icon large color="warning">mdi-database-arrow-up</v-icon>
                      </v-btn>
                      <v-btn @click="$router.push('/cashout')">
                        <span>Cash Out </span>

                        <v-icon large color="success">mdi-cash</v-icon>
                      </v-btn>
                    </v-bottom-navigation>

              </v-row>
             
                  <v-row no-gutter class="ma-1 mt-3">
                      <v-divider></v-divider>
                  </v-row>
            <v-row no-gutters class="mb-2">
                <span class="subtitle-1 text-primary mt-10">Transactions</span>
                <v-spacer/>
                 <span style="width: 140px; margin-top: 24px;"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                              label="Date"
                              :value="to_date"
                              readonly
                              filled
                              dense
                              hide-details
                              outlined
                              append-icon="mdi-calendar"
                              v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, get_wallet_transaction()" />
                    </v-menu>
               </span>
              </v-row>
                
                <div id="PrintReport">
                  <table class="print_table md-font" >
                      <tr>
                          <th class="text-left">Desc.</th>
                          <th class="text-right">AMOUNT</th>
                          <th class="text-right">PREV BAL</th>
                          <th class="text-right">CURR BAL</th>
                      </tr>
                      <tr v-for="(item, index) in transactions" :key="index">
                          <td :class="'td-'+IS_ODD(index)">
                           <span :class="STATUS_COLOR(item.type)"> {{item.description}}</span> <br/>
                            {{$moment(item.transdt).format("MMM DD, YYYY hh:mm a")}} </td>
                          <td class="text-right" :class="'td-'+IS_ODD(index)">{{$money(item.amount)}}</td>
                          <td class="text-right" :class="'td-'+IS_ODD(index)">{{$money(item.prev_balance)}}</td>
                          <td class="text-right" :class="'td-'+IS_ODD(index)">{{$money(item.cur_balance)}}</td>
                      </tr>
                  </table>
                </div>
            </v-card>
        </v-col>
    </v-row>
       <va-send-form :show="sendform" :account="user" @DialogEvent="pasEvent"/>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      to_menu: false,
      sendform: false,
        search:'',
        from_date:'',
        to_date: '',
        date_range:"",
        salesman:{},
        transactions:[],
        per_type:'Overall'
    }),
    created() {
      this.PING()
       this.CHECK_BALANCE(this.user.id)
        this.to_date =  this.$moment().format("YYYY-MM-DD")
        this.get_wallet_transaction()
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
        this.setOverlayLoading(false)
        }
    },
    computed:{
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      balance(){
        return  this.$store.state.balance.balance
      },
      total(){
        var total = this.transactions.reduce((res,item)=>{
            if(item.type == "Buy Ticket") {
              res+=this.$IsNum(item.amount)
            }
            return res
        }, 0)
        return total
      },
       wintotal(){
        var total = this.transactions.reduce((res,item)=>{
            if(item.type == "Win") {
              res+=this.$IsNum(item.amount)
            }
            return res
        }, 0)
        return total
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
      pasEvent(){
        this.sendform = false
        this.CHECK_BALANCE(this.user.id)
        this.get_wallet_transaction()
      },
      get_wallet_transaction(){
        this.transactions=[]
        this.setOverlayLoading(true)
          this.$http.post("wallet/get_transactions", {account: this.user.id, from_date: this.to_date, to_date: this.to_date}).then(response => {
           response.data.items != null?this.transactions =  response.data.items:this.transactions=[]
         this.setOverlayLoading(false)
         }).catch(e => {
           console.log(e.data)
         })
      }
    },
  }
</script>